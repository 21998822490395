import React from 'react';
import Img from 'gatsby-image';

import GradButton from '../../elements/button/gradbutton.component';
import HomeSlider from './slider.component';

import './hero.style.scss';

const Hero = ({title, text, images, link}) => (
    <div className="home-hero__wrapper mb-24 md:mb-0">
        <section className="home-hero max-w-screen-2xl mx-auto px-6 md:px-8 flex flex-wrap justify-between min-h-screen items-center pt-16">
            <div className="w-full md:w-2/5 lg:pr-16 px-5 md:px-0 mb-10 md:mb-0 mt-24 md:mt-0">
                {title &&<h1 className="inline-block mb-6 text-4xl sm:text-5xl font-semibold text-primary-default leading-tight" dangerouslySetInnerHTML={{__html: title}}/>}
                {text && <p className="leading-tight text-lg sm:text-2xl mb-8" dangerouslySetInnerHTML={{__html: text}} />}
                {link && <GradButton text={link.text} color="secondary" url={link.url} />}
            </div>
            <div className="w-full md:w-3/5 pl-5 md:pl-16 pr-5 md:pr-16 2xl:pr-0 -mb-24 md:mb-0">
                {images && <HomeSlider images={images} />}
            </div>
        </section>
    </div>
);

export default Hero;