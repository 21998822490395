import React from "react";
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from '../components/pages/home/hero.component';
import LogosList from '../components/elements/logos/logos-list.component';
import Intro from '../components/pages/home/intro.component';
import Services from '../components/pages/home/services.component';
import Testimonials from '../components/elements/testimonials/testimonials.component';
import CaseStudiesIntro from '../components/elements/case-studies/case-studies-intro.component';
import BlogIntro from '../components/elements/blog/blog-intro.component';
import Banner from '../components/pages/home/banner.component';
import Contact from '../components/pages/home/contact.component';

import logo1 from '../images/Website/clients/sidbibby.jpg';
import logo2 from '../images/Website/clients/pure.jpg';
import logo3 from '../images/Website/clients/rbl.jpg';
import logo4 from '../images/Website/clients/mabio.jpg';
import logo5 from '../images/Website/clients/yorwarths.jpg';

import growth from '../images/Website/icons/growth.svg'
import affordable from '../images/Website/icons/affordable.svg'
import results from '../images/Website/icons/results.svg'

import webDesign from '../images/Website/icons/web-design.svg'
import digitalMarketing from '../images/Website/icons/digital-marketing.svg'
import socialManagment from '../images/Website/icons/social-media-managment.svg'
import brandingGraphics from '../images/Website/icons/branding-and-graphics.svg'

const IndexPage = () => (
  <StaticQuery  
    query={query}
    render={data => {

    let content = {
      intro: [
        {title: 'Growth', icon: growth},
        {title: 'Affordable', icon: affordable},
        {title: 'Results', icon: results}
      ],
      serviceSection: {
        subTitle: "our services",
        title: "What we can do for your business",
        text: "We build powerful websites and run SEO campaigns that actually make you money.",
        services: [
          {title: "Web Design", icon: webDesign, url: '/web-design', image: data.web.childImageSharp.fluid},
          {title: "SEO", icon: digitalMarketing, url: '/seo', image: data.digital.childImageSharp.fluid},
        ],
        link: {
          url: "/services",
          text: "find out more"
        }
      },
      caseStudiesSection: {
        subTitle: "our work",
        title: "client success stories",
        caseStudies: data.caseStudies.nodes,
        button: {
          text: "view all",
          url: "/case-studies",
          color: "secondary"
        }
      },
      blogSection: {
        title: "Featured posts", 
        blogPosts: data.posts.nodes,
        button:  {
          text: "view all",
          url: "/news",
          color: "tertiary"
        }
      }
    }
    
    return (
      <Layout>
        <SEO
          keywords={[`Website Design`, `Web Design`, `Web Apps`, `Digtial Marketing`]}
          title="Home"
        />
        <Hero 
          title="Web Design & SEO built for results."
          text="Convert visitors into sales. Market to the right people. Get real results."
          images={[
            data.slider1.childImageSharp.fluid,
            data.slider2.childImageSharp.fluid,
            data.slider3.childImageSharp.fluid,
            data.slider4.childImageSharp.fluid
          ]}
          link={{
            text: 'show me how',
            url: '/web-design'
          }}
        />
        <LogosList logos={[logo1, logo2, logo3, logo4, logo5]} />
        <Intro 
          image={data.introImg.childImageSharp.fluid}
          subTitle={`we are <em class="heading-font font-bold text-secondary-default">volley digital.</em>`}
          title="A digital agency for growing businesses"
          text="We're a full-service digital agency focused on delivering results. How? By creating a digital experience that builds trust with your audience and establishing authority within your industry through your online presence. Think of us as members of your team, completely focused on achieving the best results for your business"
          link={{
            text: "contact",
            url: "/contact",
            color: "secondary"
          }}
          icons={content.intro}
        />
        <Services 
          {...content.serviceSection}
        />
        <Testimonials />
        <CaseStudiesIntro {...content.caseStudiesSection} />
        <BlogIntro showIcon {...content.blogSection} />
        <Banner 
          title="Captivate your audience and increase ROI" 
          text="Generating sales, enquiries and prospects by breaking down your target audience and goals. We’ll help to establish the key information which your audience need to see and ensure trust is built. This will allow you to achieve more with your customers. Watch your enquiries and sales skyrocket with the use of our digital marketing services."
          link1={{
            text: "find out more",
            url: "/web-design",
            color: "secondary"
          }}
        />
        <Contact 
          title="Lets talk"
          text="we would love to show you how we could help grow your business online."
          openingHours="Hours: Monday - Friday: 8:00am - 5:30pm"
          phone="07954 047 882"
          email="hello@volley.digital"
        />
      </Layout>
    )
  }} />
)

export default IndexPage;

const query = graphql`
  query {
    slider1: file(relativePath: { eq: "Website/clients/ely-exotics.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 750) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slider2: file(relativePath: { eq: "Website/clients/sid-bibbys.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 750) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slider3: file(relativePath: { eq: "Website/clients/pure-ely.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 750) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slider4: file(relativePath: { eq: "Website/clients/yorwarths-home.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 750) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    introImg: file(relativePath: { eq: "Website/digital-growth.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 850) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    web: file(relativePath: { eq: "Website/services/webs-design.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    digital: file(relativePath: { eq: "Website/services/digital-marketing.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    social: file(relativePath: { eq: "Website/services/social-media-management.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    graphics: file(relativePath: { eq: "Website/services/branding-and-graphics.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pure: file(relativePath: { eq: "Website/clients/pureely-thumbnail.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sid: file(relativePath: { eq: "Website/clients/sid-bibby-thumbnail.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    yorwarths: file(relativePath: { eq: "Website/clients/yorwarth-thumbnail.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    caseStudies: allContentfulCaseStudies(limit: 3) {
      nodes {
        rating
        slug
        title
        thumbnail {
          localFile {
            childImageSharp {
              fluid(fit: CONTAIN, maxWidth: 430, quality: 60) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    posts: allContentfulBlogPost(limit: 3) {
      nodes {
        title
        categories {
          title
        }
        slug
        createdAt
        featuredImage {
          localFile {
            childImageSharp {
              fluid(fit: CONTAIN, maxWidth: 400, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`