import React from 'react';
import Img from 'gatsby-image';

import Button from '../../elements/button/button.component';


const Intro = ({title, subTitle, icons, image, text, link}) => (
    <section className="flex flex-wrap mb-10 md:mb-20 lg:mb-28 bg-white-100">
        <div className="max-w-screen-3xl mx-auto px-6 md:px-8">
            <div className="flex flex-wrap items-center">
                <div className="w-full md:w-7/12">
                    <Img className="mb-0 p-0" alt="Phone Mockup with website" fluid={image} />
                </div>
                <div className="w-full md:w-5/12 mb-10 md:mb-0 md:pl-16">
                    {subTitle && <span className="text-sm md:text-xl mb-2 block" dangerouslySetInnerHTML={{__html: subTitle}} />}
                    {title && <h2 className="text-3xl md:text-4xl text-primary-default font-semibold max-w-sm mb-4">{title}</h2>}
                    <div className="flex flex-wrap">
                        {/* {icons && icons.map(icon => (
                            <div key={icon.title} className="text-center pr-5 md:pr-10 mb-4">
                                <img className="w-16 md:w-auto mb-0" key={icon.title} src={icon.icon} />
                                <span className="md:text-xl text-black-default">{icon.title}</span>
                            </div>
                        ))} */}
                    </div>
                    {text && <p className="mb-4">{text}</p>}
                    {link && <Button text={link.text} url={link.url} color={link.color} />}
                </div>
            </div>
        </div>
    </section>
);

export default Intro;